#left-panel {
    width: 25vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    margin-top: 10vh;
    background-color: #161616;
}

#left-panel-vl {
    width: 0px;
    height: 100%;
    border: 0.1vw solid #ef9825;
}

#left-panel-content {
    width: 100%;
    height: 100%;
    padding-left: 7.5vh;
    padding-right: 7.5vh;
    padding-top: 3.75vh;
}

#left-panel-price {
    height: 10vh;
    display: flex;
    align-items: center;
}

#left-panel-main-price {
    font-size: 7vh;
    align-self: center;
}

#left-panel-price-change {
    font-size: 2vh;
    align-self: center;
    margin-left: 1vw;
    color: #686868;
    align-self: center;
}

#left-panel-monero-stats-title {
    font-size: 3vh;
    color: #ef9825;
    margin-top: 2vh;
}

.left-panel-stat-title {
    font-size: 2.5vh;
    margin-top: 2vh;
}

.left-panel-stat {
    font-size: 2.5vh;
    margin-top:0.5vh;
}

#left-panel-monero-pairs-title {
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: 3vh;
    color: #ef9825;
}

.left-panel-price-text {
    font-size: 2.5vh;
    margin-top: 2vh;
    display: inline;
    color: #686868;
    margin-top: 0.5vh;
}

.left-panel-pair {
    font-size: 2.5vh;
}

.left-panel-info {
    font-size: 2vh;
    margin-top: 2vh;
    color: #686868;
}