header {
    width: 100%;
    height: 10vh;
    background-color: #2b2b2b;
    display: flex;
    position: fixed;
    align-items: center;
    padding: 5vh;
}

#main-logo {
    width: 3vw;
    color: #fff;
    margin-left: 2.5vw
}

#main-title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    color: #fff;
    margin-left: 1vw
}

#openmonero-links-div {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 4vw;
}

#github-img {
    width: 6.125vh;
    height: 6.125vh;
    border: 1px solid white;
    border-radius: 45px;
    margin-right: 1vw;
    padding: 1vh;
}

#discord-img {
    width: 6.125vh;
    height: 6.125vh;
    border-radius: 45px;
    padding: 1vh;
    border: 1px solid white;
}