#feed-main-div {
    width: 100%;
    height: 100%;
    padding-top: 10vh;
    padding-left: 25vw;
}

#feed-type-buttons-div {
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    padding-left: 5vw;
}

.feed-type-button {
    height: 5vh;
    border: none;
    border-radius: 20px;
    border: #686868 solid 1px;
    background-color: #161616;
    color: white;
    font-size: 2.5vh;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    padding: 0 1vw;
    margin: 1vh;
}

.feed-type-button:hover {
    background-color: #686868;
    color: white;
}

#feed-community-button {
    background-color: #2b2b2b;
    color: white;
}

#feed-content-div {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 5vw;
    background-color: #161616;
}

.feed-post-div {
    min-height: 12vh;
    border: #686868 solid 1px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 1vh;
    margin-right: 1vh;
}

.feed-post-div:hover {
    background-color: #2b2b2b;
}

.news-item-title {
    margin-top: 2vh;
    margin-bottom: 1vh;
    color: white;
}

.news-item-description {
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.news-item-source {
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.news-item-date {
    margin-top: 0.5vh;
    margin-bottom: 2vh;
}