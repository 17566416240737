* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

#main-div {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #161616;
}